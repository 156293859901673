import "core-js/modules/es.array.push.js";
export default {
  name: "redirect",
  data() {
    return {
      path: "ws:/WebSocketHub",
      socket: "",
      userInfo: {
        username: "a",
        password: "123",
        age: "1"
      },
      loading: false,
      activeName: "second",
      // 校验规则
      rules: {
        username: [{
          required: true,
          message: "账号不能为空",
          trigger: "blur"
        }],
        password: [{
          required: true,
          message: "密码不能为空",
          trigger: "blur"
        }]
      },
      error: ""
    };
  },
  methods: {
    getWxOauth2() {
      console.log('getWxOauth2');
      var state = this.$route.query.state;
      var redirect = this.$route.query.redirect ? this.$route.query.redirect : '';
      var host = location.protocol + "//" + location.host;
      console.log(redirect);
      console.log(state);
      localStorage.setItem("host", host);
      console.log('-----------跳转微信授权--------------');
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx89593e140fa7b55c&redirect_uri=' + 'http://hscj.ncsxyy.com/jump-oldman.html&response_type=code&scope=snsapi_userinfo&state=' + state + '#wechat_redirect';
    },
    login() {
      if (this.userInfo.username == "" || this.userInfo.username.trim() == "") return this.$Message.error("请输入用户名");
      if (this.userInfo.password == "" || this.userInfo.password.trim() == "") return this.$Message.error("请输入密码");
      this.loading = true;
      console.log(this.userInfo);
      this.$http.post("/api/login", this.userInfo, "正在登陆....").then(result => {
        if (result.success) {
          this.$store.commit("setUserInfo", result.data);
          this.$router.push({
            path: "/activity"
          });
        } else {
          this.$toast(result.msg);
          this.loading = false;
        }
      });
      this.loading = false;
    },
    PageInit() {
      var key = this.$route.query;
      console.log(key);
      //  用code验证登录
      if (key.code) {
        key.spread = 1;
        key.spread = 1;
        var oldcode = localStorage.getItem("code");
        if (key.code != oldcode) {
          this.$http.get("/api/wechat/auth", key, "正在登陆....").then(result => {
            console.log(result);
            if (result.success) {
              this.$toast("登陆成功,正在跳转!");
              console.log(result.data);
              this.$store.commit("setUserInfo", result.data);
              this.$router.push({
                path: "/activity"
              });
            } else {
              this.$toast(result.msg);
              //this.showQYWXCode();
            }
          });
        } else {
          this.getWxOauth2();
          //this.showQYWXCode();
        }
      } else {
        this.getWxOauth2();
        //this.showQYWXCode();
      }
    }
  },

  mounted: function () {
    localStorage.clear();
    console.log('getWxOauth2');
    var query = this.$route.query;
    this.getWxOauth2();

    // this.$toast( "加载中...");
    // this.PageInit();
    // this.init();
  }
};